import * as React from "react";
import { Link } from "react-router-dom";
import Canvas from "../canvas/Canvas";

const Home = () => {
  return (
    <main className="px-3">
      <div>
        <h1>Hi there!👋</h1>
        <p className="lead">Welcome to my litte world. </p>
        <Link className="btn btn-lg btn-secondary fw-bold" to={"contact"}>
          Projects
        </Link>
      </div>
      {/* <Canvas /> */}
    </main>
  );
};

export default Home;
